html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
a {
  background-color: initial;
}
b,
strong {
  font-weight: bolder;
}
code {
  font-family: monospace, monospace;
  font-size: 1em;
}
img {
  border-style: none;
}
button,
input,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button {
  text-transform: none;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[hidden],
template {
  display: none;
}
html {
  box-sizing: border-box;
  font-family: sans-serif;
}
*,
:after,
:before {
  box-sizing: inherit;
}
h1,
h2,
p {
  margin: 0;
}
button {
  background: transparent;
  padding: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
ul {
  margin: 0;
}
*,
:after,
:before {
  border: 0 solid #dae1e7;
}
img {
  border-style: solid;
}
textarea {
  resize: vertical;
}
img {
  max-width: 100%;
  height: auto;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
input::placeholder,
textarea::placeholder {
  color: inherit;
  opacity: 0.5;
}
[role='button'],
button {
  cursor: pointer;
}
.list-reset {
  list-style: none;
  padding: 0;
}
.bg-white {
  background-color: #fff;
}
.bg-blue {
  background-color: #3490dc;
}
.hover\:bg-blue-dark:hover {
  background-color: #2779bd;
}
.border-grey-light {
  border-color: #dae1e7;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.border {
  border-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.block {
  display: block;
}
.hidden {
  display: none;
}
.flex {
  display: -webkit-flex;
  display: flex;
}
.items-center {
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-justify-content: center;
  justify-content: center;
}
.h-10 {
  height: 2.5rem;
}
.h-16 {
  height: 4rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.ml-auto {
  margin-left: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.p-4 {
  padding: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.pin-r {
  right: 0;
}
.pin-b {
  bottom: 0;
}
.shadow-lg {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
.text-center {
  text-align: center;
}
.text-grey-dark {
  color: #8795a1;
}
.text-white {
  color: #fff;
}
.text-red-dark {
  color: #cc1f1a;
}
.text-sm {
  font-size: 0.875rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
}
.capitalize {
  text-transform: capitalize;
}
.no-underline {
  text-decoration: none;
}
.z-50 {
  z-index: 50;
}
body,
html {
  height: 100%;
}
body {
  background-color: #f8fafc;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Fira Sans, sans-serif;
}
#root {
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.chevron {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 10px 10px;
  height: 0;
  top: -11px;
  right: 0;
  width: 0;
  z-index: 12;
  content: ' ';
  position: absolute;
}
.notifications li:last-child {
  border: none;
}
.Loading {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}
.Loading > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
}
.Loading .Loading__dot-1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Loading .Loading__dot-2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ErrorContainer {
  border-width: 1px;
  position: fixed;
  z-index: 50;
  background-color: #F8D7DA;
  border-color: #ef5753;
  margin-top: 4rem;
  width: 100%;
  color: #975057;
  border-radius: 0.25rem;
}
.Error__inner {
  -webkit-align-items: center;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  max-width: 50rem;
  margin-right: auto;
  left: 50%;
}
.Error__button,
.Error__inner {
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
}
.Error__button {
  padding-left: 1rem;
  padding-right: 1rem;
}
.Error__icon {
  color: #e3342f;
}
.Error__icon:hover {
  color: #cc1f1a;
}

.Loading {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}
.Loading > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
}
.Loading .Loading__dot-1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Loading .Loading__dot-2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

#my-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #F5F5F5;
  color: grey;
  text-align: right;
}

#my-title {
  font-size: 28px;
}

#my-subtitle {
  font-size: 18px;
}


/* Smartphones (portrait) */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #my-footer {
    font-size: 60%;
  }

  #my-title {
    font-size: 14px;
  }

  #my-subtitle {
  font-size: 12px;
}

}

.Main {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding-top: .5rem;
  padding-bottom: 8rem;
}
.Main--center {
  -webkit-justify-content: center;
  justify-content: center;
}

.FormContainer {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  background-color: #fff;
  border-width: 1px;
  border-color: #dae1e7;
  padding: 2rem 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.Form__titulo {
  text-align: center;
  margin-bottom: 1rem;
}
.Form__field {
  font-size: 0.875rem;
  background-color: #f8fafc;
  border-color: #dae1e7;
  border-width: 1px;
  border-radius: 0.125rem;
  padding: 0.5rem 0.25rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
.Form__submit {
  display: block;
  color: #fff;
  background-color: #3490dc;
  text-align: center;
  border-radius: 0.25rem;
  margin: 0.5rem auto 1rem;
  padding: 0.5rem 1rem;
}
.FormContainer__info {
  text-align: center;
  margin-bottom: 1rem;
  color: #8795a1;
  font-weight: 500;
}

.Signup {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.Signup__img {
  height: 45vh;
  min-width: 400px;
  object-fit: contain;
  display: none;
  padding-top: 2.5rem;
}
@media (min-width: 768px) {
  .Signup__img {
    display: block;
  }
}

[role='button'],
button {
  cursor: pointer;
}

.SuccessContainer {
  border-width: 1px;
  position: fixed;
  z-index: 50;
  background-color:#D4EDDA;
  border-color: #2d572c;
  margin-top: 5rem;
  width: 100%;
  color: #6E9D79;
  border-radius: 0.25rem;
}
.Success__inner {
  -webkit-align-items: center;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  max-width: 50rem;
  margin-right: auto;
  left: 50%;
}
.Success__button,
.Success__inner {
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
}
.Succes__button {
  padding-left: 1rem;
  padding-right: 1rem;
}
.Success__icon {
  color:#6E9D79;
}
.Succes__icon:hover {
  color:green;
}

input:valid,
textarea:valid {
    background: #f4fff4;
}
