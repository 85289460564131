.card-header{    
    cursor: pointer;
    font-weight: bold;
}

#loading {
    /*background: #ffffff;
    height: 100%;
    width: 100%;
    padding-top: 25%;*/
    z-index: 5000;
    color: #666666;
    position: fixed;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    opacity: .80;
  }