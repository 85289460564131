
  #contenedor {
    display: table;
    margin-bottom: 24px;
    height: 100%;
    margin-top: 63px !important;
  }
  
  #contenidos {
    display: table-row;
  }
  
  #columna2 {
    display: table-cell;
    height: 100%;
    padding: 15px;
    overflow: auto;
  }
  #contentmain {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    /*overflow-x: auto;*/
  }
  #columna1 {
    display: table-cell;
    height: 100%;
    width: 200px !important;
    background-color: #111;
  }